import { RADAR_DATA_COLORS } from '../../lib/helpers/radarColors';
import { useTranslate } from '../../lib/hooks/useTranslate';
import { IMapDataLegendValues, MapDataLegend } from '../MapDataLegend/MapDataLegend';

export function MapDataRadarLegend() {
  const translate = useTranslate();

  const radarValues: IMapDataLegendValues[] = [
    { color: RADAR_DATA_COLORS[0], text: { label: '>=15', ariaLabel: translate('map/radar/above', { value: 30 }) } },
    { color: RADAR_DATA_COLORS[1], text: { label: '5', ariaLabel: '5' } },
    { color: RADAR_DATA_COLORS[2] },
    { color: RADAR_DATA_COLORS[3] },
    { color: RADAR_DATA_COLORS[4], text: { label: '<0.2', ariaLabel: translate('map/radar/below', { value: 1 }) } }
  ];

  return (
    <MapDataLegend
      values={radarValues}
      icondId={'icon-umbrella-open'}
      label={translate('units/millimetersPerHour/short')}
      ariaLabel={translate('units/millimetersPerHour/long')}
    />
  );
}
