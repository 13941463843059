import { COLOR_FILL_SUBTLE_CORE_BLUE_LIGHT, COLOR_FILL_SUBTLE_GRAY_LIGHT } from '@nrk/yr-design-tokens';
import { useEffect, useRef, useState } from 'react';
import { isReactMouseEvent } from '../../lib/helpers/event';
import { rescale } from '../../lib/helpers/graph';
import { normalizeTimeBetweenStartAndEnd } from '../../lib/helpers/intervals';
import { useTranslate } from '../../lib/hooks/useTranslate';
import { IconButton } from '../IconButton/IconButton';
import { Text } from '../Text/Text';
import './MapPlayer.scss';

export interface IPlayerTime {
  label: string;
  ariaLabel: string;
  time: string;
  tick?: 'short' | 'long';
}
interface IProps {
  startLabel: string;
  times: IPlayerTime[];
  endLabel: string;
  isPlaying: boolean;
  currentTimeIndex: number;
  nowTimeIndex?: number;
  type: 'radar' | 'wind' | 'temperature';
  playerType?: 'mini' | 'normal';
  shouldRenderBottomLabels: boolean;
  onChange: (timeIndex: number) => void;
  onBlur?: (timeIndex: number) => void;
  onPlay: () => void;
  onPause: () => void;
}

export function MapPlayer(props: IProps) {
  const {
    startLabel,
    times,
    endLabel,
    isPlaying,
    currentTimeIndex,
    nowTimeIndex,
    type,
    playerType = 'normal',
    shouldRenderBottomLabels,
    onChange,
    onBlur,
    onPlay,
    onPause
  } = props;

  const [isScrubbing, setIsScrubbing] = useState(false);

  const playheadRef = useRef<HTMLDivElement>(null);
  const ticksRef = useRef<HTMLDivElement>(null);

  const translate = useTranslate();

  const startTime = times[0].time;
  const endTime = times[times.length - 1].time;

  const normalizedCurrentTime = normalizeTimeBetweenStartAndEnd({
    start: startTime,
    end: endTime,
    time: times[currentTimeIndex].time
  });

  const normalizedNowTime =
    nowTimeIndex != null
      ? normalizeTimeBetweenStartAndEnd({
          start: startTime,
          end: endTime,
          time: times[nowTimeIndex].time
        })
      : null;

  function handleScrubStart(event: React.MouseEvent | React.TouchEvent) {
    // Need to prevent default when it is a mouse event to prevent text selection while scrubbing.
    if (isReactMouseEvent(event)) {
      event.preventDefault();
    }

    setIsScrubbing(true);
  }

  useEffect(() => {
    function handleScrubMove(event: MouseEvent | TouchEvent) {
      if (isScrubbing === false || playheadRef.current == null || ticksRef.current == null) {
        return;
      }

      event.preventDefault();

      const clientX = event instanceof MouseEvent ? event.clientX : event.touches[0].clientX;
      // We use the position of the mouse inside the player to get the rescaled time value between the start time and end time.
      const dragTime = getTimeFromPosition({ element: ticksRef.current, x: clientX, times });
      // Find closest index based on the position of the mouse.
      const closestIndex = getClosestIndexFromTime({ times, positionTime: dragTime });

      onChange(closestIndex);
    }

    function handleScrubEnd(event: MouseEvent | TouchEvent) {
      if (isScrubbing === false || ticksRef.current == null) {
        return;
      }

      // Stop playhead from moving when releasing the finger or the mouse
      setIsScrubbing(false);

      if (onBlur) {
        // Since event.touches is a list of all the touch objects representing all current points of contact with the surface
        // we need to use changedTouches when checking for touchend as there are no longer contact.
        // event.changedTouches is a list of objects which states changed between the previous touch event and this one.
        const clientX = event instanceof MouseEvent ? event.clientX : event.changedTouches[0].clientX;
        // We use the position of the mouse inside the player to get the rescaled time value between the start time and end time.
        const dragTime = getTimeFromPosition({ element: ticksRef.current, x: clientX, times });
        // Find closest index based on the position of the mouse.
        const closestIndex = getClosestIndexFromTime({ times, positionTime: dragTime });

        onBlur(closestIndex);
      }
    }

    document.addEventListener('touchmove', handleScrubMove, { passive: false });
    document.addEventListener('touchend', handleScrubEnd);
    document.addEventListener('mousemove', handleScrubMove);
    document.addEventListener('mouseup', handleScrubEnd);

    return () => {
      document.removeEventListener('touchmove', handleScrubMove);
      document.removeEventListener('touchend', handleScrubEnd);
      document.removeEventListener('mousemove', handleScrubMove);
      document.removeEventListener('mouseup', handleScrubEnd);
    };
  }, [isScrubbing, onBlur, onChange, times]);

  function handleTicksClick(event: React.MouseEvent | React.TouchEvent) {
    const clientX = isReactMouseEvent(event) ? event.clientX : event.touches[0].clientX;
    if (ticksRef.current == null) {
      return;
    }

    // We use the position of the mouse inside the player to get the rescaled time value between the start time and end time.
    const clickTime = getTimeFromPosition({ element: ticksRef.current, x: clientX, times });
    // Find closest index based on the position of the click.
    const clickIndex = getClosestIndexFromTime({ times, positionTime: clickTime });

    onChange(clickIndex);

    if (onBlur) {
      onBlur(clickIndex);
    }
  }

  return (
    <div className="map-player" data-player-type={playerType}>
      <IconButton
        as="button"
        className="map-player__button"
        buttonSize={playerType === 'mini' ? 'small' : 'medium'}
        ariaLabel={isPlaying ? translate(`mapPlayer/${type}/pause`) : translate(`mapPlayer/${type}/play`)}
        onClick={isPlaying ? onPause : onPlay}
        icon={isPlaying ? 'icon-stop' : 'icon-play'}
      ></IconButton>

      <div className="map-player__container">
        <Text size="5" weight="bold" as="div" className="map-player__current-time-label">
          {times[currentTimeIndex].label}
        </Text>
        <div
          className="map-player__track"
          style={{
            background: `linear-gradient(to right,
                ${COLOR_FILL_SUBTLE_CORE_BLUE_LIGHT} 0%,
                ${COLOR_FILL_SUBTLE_CORE_BLUE_LIGHT} ${normalizedCurrentTime * 100}%,
                ${COLOR_FILL_SUBTLE_GRAY_LIGHT} ${normalizedCurrentTime * 100}%,
                ${COLOR_FILL_SUBTLE_GRAY_LIGHT} 100%`
          }}
        >
          <input
            aria-label={translate('mapPlayer/slider')}
            className="map-player__range"
            type="range"
            min="0"
            max={times.length - 1}
            value={currentTimeIndex}
            step="1"
            aria-valuetext={`${times[currentTimeIndex].ariaLabel}`}
            onChange={event => {
              const index = Number(event.target.value);
              onChange(index);

              if (onBlur) {
                onBlur(index);
              }
            }}
          />
          {/* The player is accessible using the input range */}
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
          <div
            className="map-player__ticks"
            data-player-type={playerType}
            ref={ticksRef}
            onMouseDown={handleTicksClick}
            onTouchStart={handleTicksClick}
          >
            {times.map((time, index) => {
              const normalizedTime = normalizeTimeBetweenStartAndEnd({
                start: startTime,
                end: endTime,
                time: time.time
              });
              return (
                <div
                  key={time.time}
                  className="map-player__tick"
                  data-is-played={index <= currentTimeIndex}
                  data-type={time.tick}
                  style={{ left: `${normalizedTime * 100}%` }}
                ></div>
              );
            })}
          </div>
          {/* Need to access the playhead */}
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
          <div
            className="map-player__playhead"
            ref={playheadRef}
            onMouseDown={handleScrubStart}
            onTouchStart={handleScrubStart}
            style={{ left: `${normalizedCurrentTime * 100}%` }}
          />
        </div>

        {shouldRenderBottomLabels === true && playerType === 'normal' && (
          <Text as="div" size="5" className="map-player__bottom-labels">
            <div className="map-player__start-label">{startLabel}</div>
            {normalizedNowTime != null && (
              <div className="map-player__now-label" style={{ left: `${normalizedNowTime * 100}%` }}>
                {translate('grammar/now')}
              </div>
            )}
            <div className="map-player__end-label">{endLabel}</div>
          </Text>
        )}
      </div>
    </div>
  );
}

function getTimeFromPosition({ element, x, times }: { element: HTMLElement; x: number; times: IPlayerTime[] }) {
  const rect = element.getBoundingClientRect();

  const timeStart = new Date(times[0].time).getTime();
  const timeEnd = new Date(times[times.length - 1].time).getTime();

  if (x < rect.left) {
    return timeStart;
  }

  if (x > rect.right) {
    return timeEnd;
  }

  return Math.round(
    rescale({
      value: x,
      from: { min: rect.left, max: rect.right },
      to: { min: timeStart, max: timeEnd }
    })
  );
}

function getClosestIndexFromTime({ times, positionTime }: { times: IPlayerTime[]; positionTime: number }) {
  const closestTime = times.reduce((accumulator, currentTime) => {
    return Math.abs(new Date(currentTime.time).getTime() - positionTime) <
      Math.abs(new Date(accumulator.time).getTime() - positionTime)
      ? currentTime
      : accumulator;
  });

  return times.indexOf(closestTime);
}
