import { ILightningLayerEvent, LightningLayer } from '@nrk/yr-map';
import { useEffect, useState } from 'react';
import { useAppState } from '../../app/contexts/AppStateContext';
import { useTranslate } from '../../lib/hooks/useTranslate';
import { ButtonGroup, IGroupedButtonProps } from '../ButtonGroup/ButtonGroup';
import './MapLightningToolbar.scss';

interface IProps {
  layer: LightningLayer;
}

export function MapLightningToolbar(props: IProps) {
  const { layer } = props;

  const { currentPage } = useAppState();
  const { embedded } = currentPage.details.query;
  const [activeHourIndex, setActiveHourIndex] = useState<number>(layer.getActiveHourIndex());
  const [hours, setHours] = useState<number[]>(layer.getHours());

  const translate = useTranslate();

  useEffect(() => {
    function handleEvent(event: ILightningLayerEvent) {
      if (event.type === 'lightning-layer:active-hour-index') {
        setActiveHourIndex(event.index);
      }

      if (event.type === 'lightning-layer:hours') {
        setHours(event.hours);
      }
    }

    layer.addEventListener(handleEvent);

    return () => {
      layer.removeEventListener(handleEvent);
    };
  }, [layer]);

  // We do not want to show the toolbar when the map is embedded in articles at nrk.no.
  // See https://nrk.enterprise.slack.com/archives/C0796EEJLCR/p1719482594794849?thread_ts=1719389837.499949&cid=C0796EEJLCR
  if (embedded === true) {
    return null;
  }

  const toolbarHours: IGroupedButtonProps[] = hours.map((hour, index) => {
    if (hour === 1) {
      return {
        buttonId: index.toString(),
        text: translate('mapPage/lightningToolbar/lastHour'),
        as: 'button',
        className: 'map-lightning-toolbar__button'
      };
    } else {
      return {
        buttonId: index.toString(),
        text: translate('mapPage/lightningToolbar/lastHours', { hour }),
        as: 'button',
        className: 'map-lightning-toolbar__button'
      };
    }
  });

  return (
    <div className="map-lightning-toolbar">
      <ButtonGroup
        buttons={toolbarHours}
        removeGap={true}
        activeButtonId={activeHourIndex.toString()}
        onChange={button => {
          layer.setActiveHourIndex(Number(button.buttonId));
          setActiveHourIndex(Number(button.buttonId));
        }}
      />
    </div>
  );
}
