import { IMapStyle, IWeatherLayerEvent, WeatherLayer, YrMap } from '@nrk/yr-map';
import { useEffect, useState } from 'react';
import { useAppState } from '../../app/contexts/AppStateContext';
import settings from '../../app/settings';
import { track } from '../../lib/analytics/track';
import {
  countStorageHasReachedMaxCount,
  incrementCountStorage,
  MAP_TYPE_WEATHER_COUNT_STORAGE_ID
} from '../../lib/helpers/countStorage';
import { getZoomLevelsForLayer } from '../../lib/helpers/zoomLevels';
import { useLocaleCode } from '../../lib/hooks/useLocaleCode';
import { useTranslate } from '../../lib/hooks/useTranslate';
import { getWeatherMapDescription } from '../../pages/map/helpers/helpers';
import { MAP_PORTAL_SNACKBAR_ID, MAP_PORTAL_TYPE_TOOLBAR_ID } from '../Map/Map';
import { MapSnackbar } from '../MapSnackbar/MapSnackbar';
import { MapWeatherToolbar } from '../MapWeatherToolbar/MapWeatherToolbar';
import { PortalWrapper } from '../PortalWrapper/PortalWrapper';
import { MapTypeWeather__Popup } from './MapTypeWeather__Popup';

const WEATHER_LAYER_ID = 'weather';

interface IProps {
  locationId?: string;
  map: YrMap;
  style: IMapStyle;
  setAriaLabel: (label: string) => void;
}

export function MapTypeWeather(props: IProps) {
  const { locationId, map, style, setAriaLabel } = props;
  const { currentPage } = useAppState();
  const { zoom, embedded } = currentPage.details.query;

  const translate = useTranslate();
  const localeCode = useLocaleCode();
  const [layer, setLayer] = useState<WeatherLayer>();
  const [hasTrackedPan, setHasTrackedPan] = useState(false);
  const shouldShowSnackbar = countStorageHasReachedMaxCount(MAP_TYPE_WEATHER_COUNT_STORAGE_ID) === false;
  const [showSnackbar, setShowSnackbar] = useState(shouldShowSnackbar);

  const zoomLevels = getZoomLevelsForLayer({ embedded, layer: 'weather', zoom, locationId });

  useEffect(() => {
    if (locationId == null) {
      incrementCountStorage(MAP_TYPE_WEATHER_COUNT_STORAGE_ID);
    }
  }, [locationId]);

  useEffect(() => {
    if (layer != null) {
      return;
    }

    const mapLayer = map.getLayer(WEATHER_LAYER_ID);

    if (mapLayer != null) {
      setLayer(mapLayer.layer as WeatherLayer);
    } else {
      setLayer(new WeatherLayer({ id: WEATHER_LAYER_ID, map, localeCode, locationId }));
    }
  }, [map, locationId, localeCode, layer]);

  useEffect(() => {
    if (layer == null) {
      return;
    }

    if (map.getLayer(WEATHER_LAYER_ID) != null) {
      map.showLayer(WEATHER_LAYER_ID);
      return;
    }

    map.addLayer({
      layer,
      bounds: locationId == null ? settings.map.bounds.weather : undefined,
      zoomLevels,
      pin: 'dynamic-secondary-without-primary',
      styleId: style.id
    });
    map.showLayer(WEATHER_LAYER_ID);
  }, [map, layer, style, setAriaLabel, translate, locationId, zoomLevels]);

  useEffect(() => {
    if (layer == null) {
      return;
    }

    function handleEvent(event: IWeatherLayerEvent) {
      if (event.type === 'weather-layer:description-values') {
        const description = getWeatherMapDescription({
          descriptionValues: event.descriptionValues,
          translate
        });

        const ariaLabel =
          event.descriptionValues.length > 0
            ? `${translate('mapPage/types/weather/name')}. ${description}`
            : `${translate('mapPage/types/weather/name')}`;

        setAriaLabel(ariaLabel);
      }

      if (event.type === 'weather-layer:click') {
        // Increments snackbar closed because its closed when the map is clicked
        if (showSnackbar) {
          setShowSnackbar(false);
          incrementCountStorage(MAP_TYPE_WEATHER_COUNT_STORAGE_ID);
        }
      }
    }

    // Track the first time someone drags in the weather layer.
    function handleDragStart() {
      if (hasTrackedPan === false) {
        setHasTrackedPan(true);
        track.event({ category: 'map_weather', action: 'pan' });
      }
    }

    layer.addEventListener(handleEvent);
    map.maplibregl.on('dragstart', handleDragStart);

    return () => {
      layer.removeEventListener(handleEvent);
      map.maplibregl.off('dragstart', handleDragStart);
    };
  }, [hasTrackedPan, layer, localeCode, map, setAriaLabel, translate, showSnackbar]);

  function handleCloseSnackbar() {
    setShowSnackbar(false);
  }

  if (layer == null) {
    return null;
  }

  return (
    <div className="map-type-weather">
      <PortalWrapper id={MAP_PORTAL_TYPE_TOOLBAR_ID}>
        <MapWeatherToolbar layer={layer} standalone={locationId == null} />
      </PortalWrapper>

      {locationId == null && showSnackbar && (
        <PortalWrapper id={MAP_PORTAL_SNACKBAR_ID}>
          <MapSnackbar onClose={handleCloseSnackbar} />
        </PortalWrapper>
      )}

      <MapTypeWeather__Popup layer={layer} map={map} />
    </div>
  );
}
