import { autoUpdate, offset, useFloating } from '@floating-ui/react-dom';
import CoreToggle from '@nrk/core-toggle/jsx';
import { IWindLayerEvent, WindLayer } from '@nrk/yr-map';
import { useEffect, useRef, useState } from 'react';
import { useTranslate } from '../../lib/hooks/useTranslate';
import { Button } from '../Button/Button';
import { CloseButton } from '../CloseButton/CloseButton';
import { MapIconButton } from '../MapIconButton/MapIconButton';
import './MapWindA11yMenu.scss';

interface IProps {
  layer: WindLayer;
}

export function MapWindA11yMenu(props: IProps) {
  const { layer } = props;
  const translate = useTranslate();

  const [active, setActive] = useState(false);
  const [particlesIsShown, setParticlesIsShown] = useState<boolean>();

  useEffect(() => {
    function handleEvent(event: IWindLayerEvent) {
      if (event.type === 'wind-layer:show-particles') {
        setParticlesIsShown(event.value);
      }
    }

    layer.addEventListener(handleEvent);

    return () => {
      layer.removeEventListener(handleEvent);
    };
  }, [layer]);

  const rootRef = useRef<HTMLDivElement>(null);
  const { x, y, reference, floating, strategy } = useFloating({
    placement: 'bottom-end',
    strategy: 'fixed',
    whileElementsMounted: autoUpdate,
    middleware: [offset(10)]
  });

  function handleMapWindA11yMenuClick(event: React.MouseEvent) {
    if (event.target === rootRef.current) {
      setActive(false);
    }
  }

  // particlesIsShown is set when we get the dispatched event from yr-map
  // Untill we receive this event we are not showing the a11y menu
  if (particlesIsShown == null) {
    return null;
  }

  return (
    // The `onClick` even handler is only used as an additional way to close the map layer selector
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div className="map-wind-a11y-menu" data-active={active} ref={rootRef} onClick={handleMapWindA11yMenuClick}>
      <MapIconButton
        ref={reference}
        id="map-a11y-button"
        icon="icon-dots-vertical"
        ariaLabel={translate('map/a11yMenu/ariaLabel')}
      />
      <CoreToggle
        hidden={active === false}
        onToggle={event => {
          // Reflect the `hidden` attribute to our local variable.
          // The CoreToggle component is not a controlled component
          // and will set itself to hidden when e.g. the user presses escape.
          // We want our local variable to match CoreToggle's `hidden` attribute
          // because CoreToggle does listen for when the attribute changes
          // which means we can close CoreToggle by changing `hidden` from `true` to `false`,
          // but in order to do that we need to keep the local variable in sync.
          setActive(!event.target.hidden);
        }}
      >
        <div
          ref={floating}
          className="map-wind-a11y-menu__dialog"
          style={{
            position: strategy,
            top: y ?? 0,
            left: x ?? 0,
            width: 'max-content'
          }}
        >
          <div className="map-wind-a11y-menu__dialog-header-row">
            <CloseButton as="button" onClick={() => setActive(false)} />
          </div>

          <Button
            as="button"
            buttonType="bleed"
            buttonVariant="neutral"
            className="map-wind-a11y-menu__button"
            iconBefore={particlesIsShown ? 'icon-stop' : 'icon-play'}
            onClick={() => {
              setParticlesIsShown(!layer.particlesLayer?.shouldShowParticles);
              layer.particlesLayer?.toggleShouldShowParticles();
            }}
          >
            {particlesIsShown ? translate('map/a11yMenu/animation/stop') : translate('map/a11yMenu/animation/start')}
          </Button>
        </div>
      </CoreToggle>
    </div>
  );
}
