import CoreDialog from '@nrk/core-dialog/jsx';
import noScroll from 'no-scroll';
import { useEffect, useRef, useState } from 'react';
import { useAppState } from '../../app/contexts/AppStateContext';
import { isAncestor } from '../../lib/element';
import { useTranslate } from '../../lib/hooks/useTranslate';
import { eventHasActiveModifiers, LEFT, RIGHT } from '../../lib/keyboard';
import { Button } from '../Button/Button';
import { CloseButton } from '../CloseButton/CloseButton';
import { Heading } from '../Heading/Heading';
import './ModalDialog.scss';

interface IBaseProps {
  size?: 'small' | 'large';
  title?: React.ReactNode | string;
  ariaLabel?: string;
  testId?: string;
  children: React.ReactNode;
}

interface IRenderOnServerTrueProps extends IBaseProps {
  renderOnServer: true;
  previousUrl?: string;
  nextUrl?: string;
  onCloseUrl: string;
}

interface IRenderOnServerFalseProps extends IBaseProps {
  renderOnServer: false;
  onClose: () => void;
}

type IProps = IRenderOnServerTrueProps | IRenderOnServerFalseProps;

export function ModalDialog(props: IProps) {
  const { ariaLabel, testId, size = 'large', title, children } = props;

  const translate = useTranslate();
  const { history, isFirstRender, previousPage } = useAppState();
  const coreDialogRef = useRef<HTMLDivElement>(null);

  const hasPreviousPage = previousPage != null;

  // Start out by not rendering the modal dialog so we can toggle no scroll before we render it.
  // When server rendering a modal dialog, however, or when hydrating a server rendered modal dialog,
  // the modal dialog should start out visible to prevent mismatch between server and client markup.
  const [visible, setVisible] = useState(isFirstRender);

  // We want to toggle no scroll before the browser renders the modal dialog for the first time.
  // Without this the page's scrollbar and the modal dialog's scrollbar could both briefly be visible at the same time.
  useEffect(() => {
    noScroll.on();
    setVisible(true);

    return () => {
      noScroll.off();
    };
  }, []);

  // Listen to key events so we can navigate to the previous and next dialog.
  // We need to rereun the effect when the previous or next URL changes so the
  // callback handlers won't continue to use stale previous or next URLs
  // when the user press left or right.
  // The dependency list is a bit ugly since we have to force TypeScript to
  // treat props as if it were `IRenderOnServerTrueProps`.
  useEffect(() => {
    function handleKeyDown(event: KeyboardEvent) {
      if (isRenderOnServerFalse(props)) {
        return;
      }

      // Do nothing if if the key event has any active modifiers.
      // We don't want to override any potential browser shortcuts such as cmd+left/right
      if (eventHasActiveModifiers(event)) {
        return;
      }

      // We only care about left and right arrows
      if (event.keyCode !== LEFT && event.keyCode !== RIGHT) {
        return;
      }

      const url = event.keyCode === LEFT ? props.previousUrl : props.nextUrl;
      if (url == null) {
        return;
      }

      // Navigate to the previous or next URL without pushing a new state to history
      history.replace(url);
    }

    document.body.addEventListener('keydown', handleKeyDown, false);

    return () => {
      document.body.removeEventListener('keydown', handleKeyDown, false);
    };
  }, [props, history]);

  function handleDialogClose() {
    if (isRenderOnServerFalse(props)) {
      props.onClose();
      return;
    }

    if (hasPreviousPage === true) {
      window.history.back();
      return;
    }

    // Navigate to the previous page's URL without pushing a new state to history.
    // We do this because we want it to feel like this URL is just a dialog
    // within the current page, and the user should not see the dialog again
    // if they click back in their browser after closing the dialog.
    history.replace(props.onCloseUrl);
  }

  // Close the dialog if the user clicks on the backdrop
  function handleDialogClick(event: React.MouseEvent) {
    if (coreDialogRef.current == null) {
      return;
    }

    if (!(event.target instanceof HTMLElement)) {
      return;
    }

    // Don't close the dialog if the user clicked inside the dialog
    if (isAncestor(coreDialogRef.current, event.target)) {
      return;
    }

    handleDialogClose();
  }

  let closeButton = (
    <CloseButton as="button" buttonSize="medium" className="modal-dialog__close-button" onClick={handleDialogClose} />
  );

  // Render the close button as a link to the given `onCloseUrl`
  // when rendering on the server or when we have no page to go back to.
  if (isRenderOnServerTrue(props) && (isFirstRender === true || hasPreviousPage === false)) {
    closeButton = (
      <CloseButton
        as="link"
        buttonSize="medium"
        className="modal-dialog__close-button"
        href={props.onCloseUrl}
        onClick={event => {
          event.preventDefault();

          handleDialogClose();
        }}
      />
    );
  }

  if (visible === false) {
    return null;
  }

  return (
    // The `onClick` event handler on the div is only used as an extra way to close the dialog by clicking on the
    // backdrop, so it's not an accessibility-problem.
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div className={`modal-dialog modal-dialog--${size}`} onClick={handleDialogClick}>
      <div className="modal-dialog__scroll-container">
        <div className="modal-dialog__flex-container">
          <CoreDialog
            forwardRef={coreDialogRef}
            className="modal-dialog__dialog"
            aria-label={ariaLabel}
            // We use our own backdrop with our own click handler instead of using the built-in backdrop provided by
            // CoreDialog. We do this because the backdrop needs be rendered behind `.modal-dialog__scroll-container`
            // so that the vertical scrollbar is rendered in front of the backdrop. Since CoreDialog's built-in
            // backdrop is a descendant of `.modal-dialog__scroll-container` it's difficult to position it such that
            // it isn't rendered in front of the vertical scrollbar.
            // See https://nrknyemedier.atlassian.net/browse/YR-3093
            backdrop={'off'}
            onDialogToggle={handleDialogClose}
          >
            <div className="modal-dialog__content" data-testid={testId}>
              <div className="modal-dialog__header">
                <div className="modal-dialog__header-content">
                  {title != null && (
                    // We use aria-live="polite" so the dialog title will be announced by screen readers
                    // when a user e.g. changes the current day in the hourly weather dialog.
                    <Heading level="h2" size="3" aria-live="polite" data-testid="modal-dialog-heading">
                      {title}
                    </Heading>
                  )}

                  {closeButton}
                </div>
              </div>

              {children}
              {isRenderOnServerTrue(props) && (
                <div className="modal-dialog__navigation">
                  {props.previousUrl && (
                    <Button
                      as="link"
                      className="modal-dialog__navigation-first-button"
                      iconBefore="icon-chevron-left"
                      // When changing pages we want each day to have its own unique URL
                      // so users can share the URL to the day they're currently viewing.
                      // We want the entire dialog itself to behave like a single page, however,
                      // so when a user opens the hourly weather dialog for day and then navigates
                      // five days in the future, they can close the entire dialog simply by
                      // hitting the browser back button once.
                      // We use the `data-replace-history-state="true"` attribute on the link
                      // to achieve this.
                      data-replace-history-state={true}
                      href={props.previousUrl}
                    >
                      {translate('modalDialog/previousDay')}
                    </Button>
                  )}

                  {props.nextUrl && (
                    <Button
                      as="link"
                      className="modal-dialog__navigation-second-button"
                      iconAfter="icon-chevron-right"
                      // See the comment for `data-replace-history-state={true}` for the previous day link
                      data-replace-history-state={true}
                      href={props.nextUrl}
                    >
                      {translate('modalDialog/nextDay')}
                    </Button>
                  )}
                </div>
              )}
            </div>
          </CoreDialog>
        </div>
      </div>

      <div className="modal-dialog__backdrop" />
    </div>
  );
}

function isRenderOnServerTrue(props: IProps): props is IRenderOnServerTrueProps {
  return props.renderOnServer === true;
}

function isRenderOnServerFalse(props: IProps): props is IRenderOnServerFalseProps {
  return props.renderOnServer === false;
}
