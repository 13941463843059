// In addion to the colors I will define here, the source data also has these two colors, that we are using to render the map in yr-map:
// The official documentation for the colors is here: https://beta.yr-maps.met.no/concepts#precipitation-nowcast
// no-data: #FFFFFF
// no-precipitation:  #000000 ( in practise this means x < 0.03 mm/h )

// All values are in mm/h, taken directly from the documentation:
// 0.03 <= x < 0.05: #91E4FF
// 0.05 <= x < 0.2: #5ED7FF
// 0.2 <= x < 1: #00AAFF
// 1 <= x < 5: #0080FF
// 5 <= x < 15: #0055FF
// 15 <= x: #7A0087

//TODO(as): This can be rewritten to an object that has both color and value. For now we still hardcode some values in MapDataRadarLegend
export const RADAR_DATA_COLORS = ['#7A0087', '#0055FF', '#0080FF', '#00AAFF', '#5ED7FF', '#91E4FF'];
