import { YrMap } from '@nrk/yr-map';
import { MapPopup__Container } from './MapPopup__Container';
import { EmptyText } from '../EmptyText/EmptyText';
import { CloseButton } from '../CloseButton/CloseButton';
import { IPoint } from '../../model/point';
import { Text } from '../Text/Text';

interface IProps {
  map: YrMap;
  pin?: 'primary' | 'secondary';
  children?: boolean;
  onClose: () => void;
  showLocationList?: boolean;
  point: IPoint;
  clickedCurrentLocationInWeatherLayer?: boolean;
  layer?: 'wind' | 'weather' | 'radar' | 'lightning';
}

export function MapPopup__Loader(props: IProps) {
  const {
    map,
    pin,
    children,
    onClose,
    showLocationList = false,
    clickedCurrentLocationInWeatherLayer,
    point,
    layer
  } = props;

  return (
    <MapPopup__Container map={map} pin={pin} point={point} layer={layer}>
      <EmptyText className="map-popup__title" animation={true} type="h3" height={1.25} length={10}></EmptyText>
      <Text as="p" size="5" className="map-popup__subtitle">
        <EmptyText animation={true} type="span" length={20} height={1.125}></EmptyText>
      </Text>
      {children === true && (
        <div className="map-type-wind__popup-content">
          <EmptyText type="span" animation={true} length={10} height={1.5}></EmptyText>
        </div>
      )}
      {clickedCurrentLocationInWeatherLayer === false ||
      (clickedCurrentLocationInWeatherLayer == null && pin === 'secondary') ? (
        <div className="map-popup__navigation-button">
          <EmptyText type="span" animation={true} length={13} height={2.7}></EmptyText>
        </div>
      ) : null}
      {showLocationList === true && (
        <div className="map-popup__location-list">
          <EmptyText
            className="map-popup__location-list-header"
            animation={true}
            type="p"
            height={1.1}
            length={13}
          ></EmptyText>
          <EmptyText
            className="map-popup__location-list-distance-text-loader"
            animation={true}
            type="span"
            length={35}
          ></EmptyText>
          <EmptyText
            className="map-popup__location-list-distance-text-loader"
            animation={true}
            type="span"
            length={35}
          ></EmptyText>
          {/* Show different EmptyText loaders and amount for mobile and desktop devices. */}
          {/* If we do not show another EmptyText line on mobile devices, the popup may "bounce" from one side of the pin to the other after the data has loaded.
          This can also backfire if a user has clicked in the middle of the ocean. The loader will then be larger then the popup with data. */}
          <EmptyText
            className="map-popup__location-list-distance-text-loader-mobile"
            animation={true}
            type="span"
            length={23}
          ></EmptyText>
          <EmptyText
            className="map-popup__location-list-distance-text-loader-mobile"
            animation={true}
            type="span"
            length={23}
          ></EmptyText>
          <EmptyText
            className="map-popup__location-list-distance-text-loader-mobile"
            animation={true}
            type="span"
            length={23}
          ></EmptyText>
        </div>
      )}
      <div className="map-popup__close-button">
        <CloseButton as="button" onClick={onClose} />
      </div>
    </MapPopup__Container>
  );
}
