import { IMapStyle, TemperatureLayer, YrMap } from '@nrk/yr-map';
import { useEffect, useState } from 'react';
import { useAppState } from '../../app/contexts/AppStateContext';
import settings from '../../app/settings';
import { useTheme } from '../../contexts/ThemeContext/ThemeContext';
import { useFetchTemperatureData } from '../../data/map/hooks';
import { track } from '../../lib/analytics/track';
import { getZoomLevelsForLayer } from '../../lib/helpers/zoomLevels';
import { useCurrentLocationId } from '../../lib/hooks';
import { useLocaleCode } from '../../lib/hooks/useLocaleCode';
import { useTranslate } from '../../lib/hooks/useTranslate';
import { changePin } from '../../lib/map/changePin';
import { MAP_PORTAL_INFO_BUTTON_ID, MAP_PORTAL_LEGEND_ID, MAP_PORTAL_TYPE_TOOLBAR_ID } from '../Map/Map';
import { MapDataTemperatureLegend } from '../MapDataTemperatureLegend/MapDataTemperatureLegend';
import { MapIconButton } from '../MapIconButton/MapIconButton';
import { MapTemperatureInformationDialog } from '../MapTemperatureInformationDialog/MapTemperatureInformationDialog';
import { MapTemperatureToolbar } from '../MapTemperatureToolbar/MapTemperatureToolbar';
import { PortalWrapper } from '../PortalWrapper/PortalWrapper';
import './MapTypeTemperature.scss';
import { MapTypeTemperature__Popup } from './MapTypeTemperature__Popup';

const TEMPERATURE_LAYER_ID = 'temperature';

interface IProps {
  map: YrMap;
  style: IMapStyle;
  setAriaLabel: (label: string) => void;
}

export function MapTypeTemperature(props: IProps) {
  const { map, setAriaLabel, style } = props;
  const { currentPage } = useAppState();
  const { zoom, embedded } = currentPage.details.query;
  const { theme } = useTheme();

  const translate = useTranslate();
  const localeCode = useLocaleCode();
  const locationId = useCurrentLocationId();
  const [layer, setLayer] = useState<TemperatureLayer>();
  const [showInformation, setShowInformation] = useState(false);
  const [hasTrackedPan, setHasTrackedPan] = useState(false);
  const { data: temperatureApiData } = useFetchTemperatureData();

  const zoomLevels = getZoomLevelsForLayer({ embedded, layer: 'temperature', zoom, locationId });

  useEffect(() => {
    if (layer != null || temperatureApiData == null) {
      return;
    }

    const mapLayer = map.getLayer(TEMPERATURE_LAYER_ID);

    if (mapLayer != null) {
      setLayer(mapLayer.layer as TemperatureLayer);
    } else {
      setLayer(
        new TemperatureLayer({
          id: TEMPERATURE_LAYER_ID,
          map,
          renderBeforeId: style.renderBeforeId,
          apiData: temperatureApiData
        })
      );
    }
  }, [map, layer, style, temperatureApiData]);

  useEffect(() => {
    if (layer == null) {
      return;
    }

    if (map.getLayer(TEMPERATURE_LAYER_ID) != null) {
      map.showLayer(TEMPERATURE_LAYER_ID);

      changePin({ to: 'lightmode' });
      return;
    }

    map.addLayer({
      layer,
      bounds: locationId == null ? settings.map.bounds.temperature : undefined,
      zoomLevels,
      pin: 'dynamic-secondary-with-static-primary',
      styleId: style.id
    });
    setAriaLabel(translate('mapPage/types/temperature/name'));
    map.showLayer(TEMPERATURE_LAYER_ID);

    changePin({ to: `lightmode` });
  }, [map, layer, style, locationId, setAriaLabel, translate, zoomLevels, theme]);

  useEffect(() => {
    if (layer == null) {
      return;
    }

    // Track the first time someone drags in the temperature layer.
    function handleDragStart() {
      if (hasTrackedPan === false) {
        setHasTrackedPan(true);
        track.event({ category: 'map_temperature', action: 'pan' });
      }
    }

    map.maplibregl.on('dragstart', handleDragStart);

    return () => {
      map.maplibregl.off('dragstart', handleDragStart);
    };
  }, [map, layer, hasTrackedPan, localeCode]);

  function onOpenInformation() {
    track.event({ category: 'map_temperature', action: 'open_info' });

    setShowInformation(true);
  }

  function onCloseInformation() {
    setShowInformation(false);
  }

  if (layer == null) {
    return null;
  }

  return (
    <div className="map-type-temperature">
      <PortalWrapper id={MAP_PORTAL_TYPE_TOOLBAR_ID}>
        <MapTemperatureToolbar layer={layer} />
      </PortalWrapper>

      <PortalWrapper id={MAP_PORTAL_LEGEND_ID}>
        <MapDataTemperatureLegend temperatureLayer={layer} />
      </PortalWrapper>

      <MapTypeTemperature__Popup layer={layer} map={map} />

      <PortalWrapper id={MAP_PORTAL_INFO_BUTTON_ID}>
        <MapIconButton
          data-testid="map-info-button"
          icon="icon-i"
          ariaLabel={translate('infoButton/ariaLabel')}
          onClick={onOpenInformation}
        />
      </PortalWrapper>

      {showInformation && <MapTemperatureInformationDialog layer={layer} onClose={onCloseInformation} />}
    </div>
  );
}
