import { IMapWeatherLayerInterval, IWeatherLayerEvent, WeatherLayer } from '@nrk/yr-map';
import yrTime from '@nrk/yr-time';
import { useEffect, useState } from 'react';
import { track } from '../../lib/analytics/track';
import { createTimeIntervalLabel } from '../../lib/helpers/time';
import { useTranslate } from '../../lib/hooks/useTranslate';
import { ButtonGroup, IGroupedButtonProps } from '../ButtonGroup/ButtonGroup';
import './MapWeatherToolbar.scss';

interface IProps {
  layer: WeatherLayer;
  standalone: boolean;
}

export function MapWeatherToolbar(props: IProps) {
  const { layer, standalone } = props;

  const translate = useTranslate();
  const [activeIntervalIndex, setActiveIntervalIndex] = useState<number>(layer.getCurrentIndex());
  const [intervals, setIntervals] = useState<IMapWeatherLayerInterval[] | undefined>(layer.getIntervals());

  useEffect(() => {
    function handleEvent(event: IWeatherLayerEvent) {
      if (event.type === 'weather-layer:intervals') {
        setIntervals(event.intervals);
      }

      if (event.type === 'weather-layer:active-interval-index') {
        setActiveIntervalIndex(event.index);
      }
    }
    layer.addEventListener(handleEvent);

    return () => {
      layer.removeEventListener(handleEvent);
    };
  }, [layer]);

  if (intervals == null || activeIntervalIndex == null) {
    // Because safari is having a problem rendering new items into an empty portal on a rerender,
    // we need to render an empty div with the same class name before we get the data.
    // If not we will show an invisble MapWeatherToolbar on safari mobile devices before any action is taken.
    return <div className="map-weather-toolbar__button"></div>;
  }

  const clientTimeOffset = yrTime.create().offset();

  const toolbarHours: IGroupedButtonProps[] = intervals.slice(0, 4).map((interval, index) => {
    return {
      buttonId: index.toString(),
      text: createTimeIntervalLabel({
        type: 'hour',
        from: standalone ? yrTime.create(interval.start).offset(clientTimeOffset).timeString : interval.start,
        to: standalone ? yrTime.create(interval.end).offset(clientTimeOffset).timeString : interval.end,
        translate,
        transform: 'sentence-case'
      }),
      ariaLabel: translate('grammar/timeRange', {
        from: standalone
          ? yrTime
              .create(interval.start)
              .offset(clientTimeOffset)
              .format('HH')
          : yrTime.create(interval.start).format('HH'),
        to: standalone
          ? yrTime
              .create(interval.end)
              .offset(clientTimeOffset)
              .format('HH')
          : yrTime.create(interval.end).format('HH')
      }),
      as: 'button',
      className: 'map-weather-toolbar__button'
    };
  });

  return (
    <div className="map-weather-toolbar">
      <ButtonGroup
        buttons={toolbarHours}
        activeButtonId={activeIntervalIndex.toString()}
        removeGap={true}
        onChange={button => {
          layer.setActiveIntervalIndex(Number(button.buttonId));
          setActiveIntervalIndex(Number(button.buttonId));
          track.event({ category: 'map_weather', action: 'choose_time', value: Number(button.buttonId) });
        }}
      />
    </div>
  );
}
