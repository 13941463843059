import { IMapStyle, LightningLayer, YrMap } from '@nrk/yr-map';
import { useEffect, useState } from 'react';
import { useAppState } from '../../app/contexts/AppStateContext';
import settings from '../../app/settings';
import { useTheme } from '../../contexts/ThemeContext/ThemeContext';
import { useFetchLightningData } from '../../data/map/hooks';
import { track } from '../../lib/analytics/track';
import { getZoomLevelsForLayer } from '../../lib/helpers/zoomLevels';
import { useCurrentLocationId } from '../../lib/hooks';
import { useLocaleCode } from '../../lib/hooks/useLocaleCode';
import { useTranslate } from '../../lib/hooks/useTranslate';
import { changePin } from '../../lib/map/changePin';
import { MAP_PORTAL_INFO_BUTTON_ID, MAP_PORTAL_LEGEND_ID, MAP_PORTAL_TYPE_TOOLBAR_ID } from '../Map/Map';
import { MapDataLightningLegend } from '../MapDataLightningLegend/MapDataLightningLegend';
import { MapIconButton } from '../MapIconButton/MapIconButton';
import { MapLightningInformationDialog } from '../MapLightningInformationDialog/MapLightningInformationDialog';
import { MapLightningToolbar } from '../MapLightningToolbar/MapLightningToolbar';
import { PortalWrapper } from '../PortalWrapper/PortalWrapper';
import { MapTypeLightning__Popup } from './MapTypeLightning__Popup';

const LIGHTNING_LAYER_ID = 'lightning';

interface IProps {
  map: YrMap;
  style: IMapStyle;
  setAriaLabel: (label: string) => void;
}

export function MapTypeLightning(props: IProps) {
  const { map, setAriaLabel, style } = props;
  const { currentPage } = useAppState();
  const { theme } = useTheme();
  const { zoom, embedded } = currentPage.details.query;

  const translate = useTranslate();
  const localeCode = useLocaleCode();
  const locationId = useCurrentLocationId();
  const [layer, setLayer] = useState<LightningLayer>();

  const [showInformation, setShowInformation] = useState(false);
  const [hasTrackedPan, setHasTrackedPan] = useState(false);
  const { data: lightningData } = useFetchLightningData();

  const zoomLevels = getZoomLevelsForLayer({ embedded, layer: 'lightning', zoom, locationId });

  useEffect(() => {
    return () => {
      if (layer != null) {
        layer.onHide();
      }
    };
  }, [layer]);

  useEffect(() => {
    if (layer != null) {
      return;
    }

    const mapLayer = map.getLayer(LIGHTNING_LAYER_ID);

    if (mapLayer != null) {
      setLayer(mapLayer.layer as LightningLayer);
    } else {
      setLayer(new LightningLayer({ id: LIGHTNING_LAYER_ID, map }));
    }
  }, [map, layer, style]);

  useEffect(() => {
    if (layer == null) {
      return;
    }

    layer.setHistoricalLightningData(lightningData);
  }, [layer, lightningData]);

  useEffect(() => {
    if (layer == null) {
      return;
    }

    if (map.getLayer(LIGHTNING_LAYER_ID) != null) {
      map.showLayer(LIGHTNING_LAYER_ID);

      changePin({ to: `${theme}mode` });
      return;
    }

    map.addLayer({
      layer,
      bounds: locationId == null ? settings.map.bounds.lightning : undefined,
      zoomLevels,
      pin: 'dynamic-secondary-with-static-primary',
      // The lightning layer should be rendered before a different layer than the other maps that use this style sheet
      renderBeforeId: 'label_city',
      styleId: style.id,
      resetWhenShown: true
    });
    setAriaLabel(translate('mapPage/types/lightning/name'));
    map.showLayer(LIGHTNING_LAYER_ID);

    changePin({ to: `${theme}mode` });
  }, [map, layer, style, locationId, setAriaLabel, translate, zoomLevels, theme]);

  useEffect(() => {
    if (layer == null) {
      return;
    }

    // Track the first time someone drags in the lightning layer.
    function handleDragStart() {
      if (hasTrackedPan === false) {
        setHasTrackedPan(true);
        track.event({ category: 'map_lightning', action: 'pan' });
      }
    }

    map.maplibregl.on('dragstart', handleDragStart);

    return () => {
      map.maplibregl.off('dragstart', handleDragStart);
    };
  }, [map, layer, hasTrackedPan, localeCode]);

  function onOpenInformation() {
    track.event({ category: 'map_lightning', action: 'open_info' });

    setShowInformation(true);
  }

  function onCloseInformation() {
    setShowInformation(false);
  }

  if (layer == null) {
    return null;
  }

  return (
    <div className="map-type-wind">
      <PortalWrapper id={MAP_PORTAL_TYPE_TOOLBAR_ID}>
        <MapLightningToolbar layer={layer} />
      </PortalWrapper>

      <PortalWrapper id={MAP_PORTAL_LEGEND_ID}>
        <MapDataLightningLegend layer={layer} />
      </PortalWrapper>

      <MapTypeLightning__Popup layer={layer} map={map} />

      <PortalWrapper id={MAP_PORTAL_INFO_BUTTON_ID}>
        <MapIconButton
          data-testid="map-info-button"
          icon="icon-i"
          ariaLabel={translate('infoButton/ariaLabel')}
          onClick={onOpenInformation}
        />
      </PortalWrapper>

      {showInformation && <MapLightningInformationDialog onClose={onCloseInformation} />}
    </div>
  );
}
