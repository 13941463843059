/**
 * Replacing the image src of all markers in the map to reflect the chosen theme.
 *
 * Does nothing if we are trying to change to the same src as the marker are already using,
 * so we dont have to think about what theme we are currently on.
 *
 * e.g Will replace the `white` dark mode marker with the `black` light mode marker if `to` is set to lightmode
 *
 * @param to Determines the theme of the image to replace the image src of all markers in the map
 */

export function changePin({ to }: { to: 'darkmode' | 'lightmode' }) {
  const primaryMarker = document.getElementsByClassName('map__primary-marker-pin')[0] as HTMLImageElement;
  const primaryMarkerHover = document.getElementsByClassName('map__primary-marker-pin--hover')[0] as HTMLImageElement;
  const secondaryMarker = document.getElementsByClassName('map__secondary-marker-pin')[0] as HTMLImageElement;

  if (
    primaryMarker != null &&
    ((primaryMarker.src.includes('darkmode') === false && to === 'darkmode') ||
      (primaryMarker.src.includes('darkmode') === true && to === 'lightmode'))
  ) {
    primaryMarker.src =
      to === 'lightmode' ? '/assets/images/map/marker-primary.png' : '/assets/images/map/marker-primary-darkmode.png';
  }

  if (
    primaryMarkerHover != null &&
    ((primaryMarkerHover.src.includes('darkmode') === false && to === 'darkmode') ||
      (primaryMarkerHover.src.includes('darkmode') === true && to === 'lightmode'))
  ) {
    primaryMarkerHover.src =
      to === 'lightmode'
        ? '/assets/images/map/marker-primary-hover.png'
        : '/assets/images/map/marker-primary-hover-darkmode.png';
  }

  if (
    secondaryMarker != null &&
    ((secondaryMarker.src.includes('darkmode') === false && to === 'darkmode') ||
      (secondaryMarker.src.includes('darkmode') === true && to === 'lightmode'))
  ) {
    secondaryMarker.src =
      to === 'lightmode'
        ? '/assets/images/map/marker-secondary.png'
        : '/assets/images/map/marker-secondary-darkmode.png';
  }
}
